import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { globalConfig } from "../config";
import Pagination from "../utils/Paginate";
const querystring = require("querystring");

const Transformers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [state, setState] = useState({
    transformations: [],
    filteredTransformers: [],
  });

  useEffect(() => {
    handleOnLoad();
  }, []);

  useEffect(() => {
    handleSearch(search);
  }, [search]);

  const handleOnLoad = () => {
    axios
      .get(
        `${globalConfig.apiDatahubUrl}/transformations/`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const transformations = res.data;
        console.log(transformations);
        setState({
          ...state,
          transformations: transformations,
          filteredTransformers: transformations,
        });
        console.log(res.data[0]);
      });
  };
  function handleDelete(id, event) {
    event.preventDefault();
    //alert('deleting service with id = ' + id);
    axios
      .delete(`${globalConfig.apiDatahubUrl}/transformations/${id}`, {
        headers: {
          Authorization: `Bearer 9a0641b5-7c8b-4f30-bf24-69248863a6e2:1279`,
        },
      })
      .then((res) => {
        const deleteResponse = res.data;
      });
    setState({ transformations: [] });
    handleOnLoad();
  }

  const indexOfLastData = currentPage * globalConfig.dataPerPage;
  const indexOfFirstData = indexOfLastData - globalConfig.dataPerPage;
  const currentData = state.filteredTransformers.slice(
    indexOfFirstData,
    indexOfLastData
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //handler for page
  const handlePage = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (search) => {
    let URL = `${globalConfig.apiDatahubUrl}/transformations?`;
    URL += `&filter[where][or][0][transformationName][regexp]=/(${search})/i`;
    axios.get(URL, globalConfig.hardcoded_header).then((res) => {
      const transformations = res.data;
      console.log(transformations);
      setState({
        ...state,
        filteredTransformers: transformations,
      });
      console.log(res.data[0]);
    });
  };

  return (
    <div class="container-fluid">
    <form>
      <div class="container-fluid">
        <div className="d-flex flex-row justify-content-between mb-3 mt-3">
          <h4>Transformations</h4>
          <Link to="/create-transformer" className="btn-success btn">
            Create Transformer
          </Link>
        </div>
        <div class="form-group">
          <input
            className="form-control"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="search"
            id="exampleFormControlInput1"
            placeholder="Search"
          />
        </div>
        <div className="form-group row">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map(({ transformationName, transformationId }) => {
                return (
                  <tr key={transformationId}>
                    <td>
                      <Link
                        to={{
                          pathname: "/manage-transformer",
                          search: `?id=${transformationId}`,
                        }}
                      >
                        {transformationId}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={{
                          pathname: "/manage-transformer",
                          search: `?id=${transformationId}`,
                        }}
                      >
                        {transformationName}
                      </Link>{" "}
                    </td>
                    <td>
                      <button
                        class="btn btn-danger"
                        onClick={(e) => this.handleDelete(transformationId, e)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        dataPerPage={globalConfig.dataPerPage}
        totaldata={state.filteredTransformers.length}
        paginate={paginate}
        currentPage={currentPage}
        onChange={handlePage}
      />
    </form>
    </div>
  );
};

export default Transformers;
