import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { SelectListComponent } from "shared-components";
import { globalConfig } from "../config";

const querystring = require("querystring");

const CreateTransformer = () => {
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState([]);
  const [state, setState] = useState({
    transformationId: 0,
    transformationName: "",
    resourceFile: "",
    className: "",
    description: "",
    transformationVersion: "",
    statusCode: 0,
    securityContextId: 0,
    createUser: 0,
    createDate: new Date(),
    lastUpdateUser: 0,
    lastUpdateDate: new Date(),
    parameters: "",
    transformationTypeCode: 0,
  });

  useEffect(() => {
    handleOnLoad();
  }, []);

  const handleOnLoad = () => {
    handleClear();
    axios
      .get(
        `${globalConfig.systemCodesUrl}/TRANSFORMATION_STATUS`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const transformation_status = res.data;
        console.log(transformation_status);
        setStatus(transformation_status);
      });
  };
  function handleClear() {
    setState({
      transformationId: 0,
      transformationName: "",
      resourceFile: "",
      className: "",
      description: "",
      transformationVersion: "",
      statusCode: 0,
      securityContextId: 0,
      createUser: 0,
      createDate: new Date(),
      lastUpdateUser: 0,
      lastUpdateDate: new Date(),
      parameters: "",
      transformationTypeCode: 0,
    });
  }
  function handleChange(event) {
    console.log(event);
    var target = event.target;

    setState({ ...state, [target.id]: target.value });
    console.log(state);
  }
  function handleCreate(event) {
    event.preventDefault();
    console.log("handling submit");

    //setState({...state,"runIntervalValue" : Number(state.runIntervalValue), "timeoutPeriod" : Number(state.timeoutPeriod)});

    var url = `${globalConfig.apiDatahubUrl}/transformations`;
    var idUrl = `${globalConfig.apiMainUrl}/next-id-generator/get-next/transformations/transformationId`;

    axios.get(idUrl, globalConfig.hardcoded_header).then((response) => {
      state.transformationId = response.data["nextId"];

      console.log("request object = " + JSON.stringify(state));
      axios
        .post(url, state, globalConfig.hardcoded_header)
        .then((response2) => {
          console.log("response.data");
          if (response2.status === 200) {
            setMessage("Success");
            window.location.replace("/transformers");
          } else {
            setMessage("Failed");
          }
          console.log(message);
          //
          event.preventDefault();
        })
        .catch((err) => {
          console.log("error - " + err);
        });
      console.log("message info - " + message);

      handleClear();
    });
  }
  return (
    <div class="container-fluid">
      <form>
        <div class="container-fluid">
          <Link
            to="/transformers"
            className="btn btn-outline-secondary mt-2 mb-3 "
          >
            Back
          </Link>

          <h4>Create Transformer</h4>
          <hr />

          <div class="form-group row">
            <label class="ContentTitle col-sm-2" value="Transformer Name">
              Transformer Name
            </label>
            <input
              class="form-control input-xlarge col-sm-8"
              id="transformationName"
              value={state.transformationName}
              onChange={handleChange}
            />
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2">Resource File Path</label>
            <input
              class="form-control input-xlarge col-sm-8"
              id="resourceFile"
              value={state.resourceFile}
              onChange={handleChange}
            />
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2">Class Name</label>
            <input
              class="form-control input-xlarge col-sm-8"
              id="className"
              value={state.className}
              onChange={handleChange}
            />
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2">Description</label>
            <input
              class="form-control input-xlarge col-sm-8"
              id="description"
              value={state.description}
              onChange={handleChange}
            />
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2">Version</label>
            <input
              class="form-control input-xlarge col-sm-8"
              id="transformationVersion"
              value={state.transformationVersion}
              onChange={handleChange}
            />
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2">Status</label>

            <select
              partialSubmit="true"
              class="form-control input-xlarge col-sm-8"
              id="indexCode"
              value={state.statusCode}
            >
              {/* <option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('TRANSFORMATION_STATUS')}" /> */}
              {status.map(
                ({ systemCodeId, systemCodeValueValue, systemCodeValueId }) => (
                  <option key={systemCodeValueId} value={systemCodeId}>
                    {systemCodeValueValue}{" "}
                  </option>
                )
              )}
            </select>
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2">Parameters</label>
            <textArea
              class="form-control input-xlarge col-sm-8"
              id="parameters"
              value={state.parameters}
              onChange={handleChange}
            />
          </div>

          <div class="form-group row">
            <label class="ContentTitle col-sm-2">Upload Transformer</label>
            <input type="file" id="fileUpload" />
            <button
              type="submit"
              value="Upload"
              class="btn btn-info"
              id="uploadActor"
            >
              Upload
            </button>
          </div>

          <hr />
          <center>
            <div class="form-group row">
              <button
                class="btn btn-success"
                value="Create"
                onClick={handleCreate}
              >
                Create
              </button>
              &nbsp;
              <button
                class="btn btn-primary"
                value="Clear"
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
          </center>
        </div>
      </form>
    </div>
  );
};

export default CreateTransformer;
