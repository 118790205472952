import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { globalConfig } from "../config";
import Pagination from "../utils/Paginate";
const querystring = require("querystring");

const Connectors = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [state, setState] = useState({
    connectors: [],
    filteredConnectors: [],
  });

  //effect for loading all connectors
  useEffect(() => {
    handleOnLoad();
  }, []);

  useEffect(() => {
    handleSearch(search);
  }, [search]);

  const handleOnLoad = () => {
    axios
      .get(
        `${globalConfig.apiDatahubUrl}/content-input-sources`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const connectors = res.data;
        console.log(connectors);
        setState({
          connectors: connectors,
          filteredConnectors: connectors,
        });
        console.log(res.data[0]);
      });
  };
  function handleDelete(id, event) {
    event.preventDefault();
    //alert('deleting ' + id);
    axios
      .delete(`${globalConfig.apiDatahubUrl}/content-input-sources/${id}`, {
        headers: {
          Authorization: `Bearer 9a0641b5-7c8b-4f30-bf24-69248863a6e2:1279`,
        },
      })
      .then((res) => {
        const deleteResults = res.data;
        console.log(deleteResults);
      });
    handleOnLoad();
  }

  const indexOfLastData = currentPage * globalConfig.dataPerPage;
  const indexOfFirstData = indexOfLastData - globalConfig.dataPerPage;
  const currentData = state.filteredConnectors.slice(
    indexOfFirstData,
    indexOfLastData
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //handler for page
  const handlePage = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (search) => {
    let URL = `${globalConfig.apiDatahubUrl}/content-input-sources?`;
    URL += `&filter[where][or][0][contentInputSourceName][regexp]=/(${search})/i&filter[where][or][1][contentSourceDescr][regexp]=/(${search})/i`;
    axios.get(URL, globalConfig.hardcoded_header).then((res) => {
      const connectors = res.data;
      console.log(connectors);
      setState({ ...state, filteredConnectors: connectors });
      console.log(res.data[0]);
    });
  };
  return (
    <div class="container-fluid">
      <form>
        <div class="container-fluid">
          <div className="d-flex flex-row justify-content-between mb-3 mt-3">
            <h4>Connectors</h4>
            <Link to="/create-connector" className="btn-success btn">
              Create Connector
            </Link>
          </div>
          <div class="form-group">
            <input
              className="form-control"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              type="search"
              id="exampleFormControlInput1"
              placeholder="Search"
            />
          </div>
          <div className="form-group row">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentData.map(
                  ({
                    contentSourceDescr,
                    contentInputSourceId,
                    contentInputSourceName,
                  }) => {
                    return (
                      <tr key={contentInputSourceId}>
                        <td class="ContentTitle">
                          <Link
                            to={{
                              pathname: `/manage-connector/${contentInputSourceId}`,
                            }}
                          >
                            {contentInputSourceId}
                          </Link>
                        </td>
                        <td class="ContentTitle">
                          <Link
                            to={{
                              pathname: `/manage-connector/${contentInputSourceId}`,
                            }}
                          >
                            {contentInputSourceName}
                          </Link>
                        </td>
                        <td class="ContentTitle">
                          <Link
                            to={{
                              pathname: `/manage-connector/${contentInputSourceId}`,
                            }}
                          >
                            {contentSourceDescr}{" "}
                          </Link>
                        </td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-danger"
                            onClick={(e) =>
                              handleDelete(contentInputSourceId, e)
                            }
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          dataPerPage={globalConfig.dataPerPage}
          totaldata={state.filteredConnectors.length}
          paginate={paginate}
          currentPage={currentPage}
          onChange={handlePage}
        />
      </form>
    </div>
  );
};

export default Connectors;
