import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SelectListComponent } from "shared-components";
import axios from "axios";
import { globalConfig } from "../config";
const querystring = require("querystring");

const ServiceScheduler = () => {
  const [auditFrequency, setAuditFrequency] = useState([]);
  const [status, setStatus] = useState([]);
  const [connectors, setConnectors] = useState([]);
  const [state, setState] = useState({
    services: [],
    service: {
      scanJobId: 0,
      scanJobName: "",
      scanJobDescription: "",
      startTime: new Date().toISOString(),
      endTime: new Date().toISOString(),
      contentInputSourceId: 0,
      securityContextId: 0,
      createUser: 0,
      createDate: new Date().toISOString(),
      lastUpdateUser: 0,
      lastUpdateDate: new Date().toISOString(),
      timeoutPeriod: 0,
      taskTime: new Date().toISOString(),
      taskDate: new Date().toISOString(),
      runIntervalValue: 0,
      runDays: "",
      runIntervalType: "",
    },
    currentServiceId: 0,
  });

  useEffect(() => {
    handleOnLoad();
  }, []);

  useEffect(() => {
    console.log(state);
  }, [state]);

  const handleOnLoad = () => {
    axios
      .get(
        `${globalConfig.apiDatahubUrl}/scan-jobs`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const services = res.data;
        console.log(services);
        setState({ ...state, services: services });
        console.log(res.data[0]);
      });

    axios
      .get(
        `${globalConfig.apiDatahubUrl}/content-input-sources`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const connectors = res.data;
        console.log(connectors);
        setConnectors(connectors);
      });

    axios
      .get(
        `${globalConfig.systemCodesUrl}/AUDIT_FREQUENCY`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const auditFrequency = res.data;
        console.log(auditFrequency);
        setAuditFrequency(auditFrequency);
      });

    axios
      .get(
        `${globalConfig.systemCodesUrl}/TRANSFORMATION_STATUS`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const transformation_status = res.data;
        console.log(transformation_status);
        setStatus(transformation_status);
      });
    // if (state.currentServiceId > 0) {
    //   axios
    //     .get(
    //       `${globalConfig.apiDatahubUrl}/scan-jobs/${state.currentServiceId}`,
    //       globalConfig.hardcoded_header
    //     )
    //     .then((res) => {
    //       const service = res.data;
    //       console.log(service);
    //       setState({ ...state, service: service });
    //     });
    // }
  };

  function handleOnChange(event) {
    setState({ ...state, currentServiceId: event.target.value });

    if (parseInt(event.target.value) !== 0) {
      axios
        .get(
          `${globalConfig.apiDatahubUrl}/scan-jobs/${event.target.value}`,
          globalConfig.hardcoded_header
        )
        .then((res) => {
          const service = res.data;
          console.log(service);
          setState({
            ...state,
            service: service,
            currentServiceId: service.scanJobId,
          });
        });
    }
  }

  return (
    <div>
      <form>
        <div class="container-fluid">
          <h4>Data Service Scheduler</h4>
          <hr />
          <div class="form-group row">
            <label class="ContentTitle col-sm-2">Select Service</label>
            <select
              class="form-control input-big col-sm-8"
              id="scanJobId"
              value={state.currentServiceId}
              onChange={handleOnChange}
            >
              <option >Select A Service</option>
              {state.services.map((service) => (
                <option key={service.scanJobId} value={service.scanJobId}>
                  {service.scanJobName}
                </option>
              ))}
            </select>
          </div>
          <hr />
          <div class="form-group row">
            <label class="ContentTitle col-sm-2" value="Name: ">
              Name
            </label>
            <input
              id="name"
              class="form-control input-big col-sm-8"
              value={state.service.scanJobName}
            ></input>
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2" value="Service ID : ">
              Service Id
            </label>
            <input
              id="selectedServiceId"
              readonly="true"
              class="form-control col-sm-8"
              value={state.service.scanJobId}
            />
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2" value="Description: ">
              Description
            </label>
            <textArea
              id="description"
              value={state.service.scanJobDescription}
              class="form-control input-big col-sm-8"
            ></textArea>
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2">Begin Date</label>
            <input
              class="form-control input-big col-sm-8"
              type="datetime-local"
              id="startTime"
              value={state.service.startTime.slice(0, 16)}
            />
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2">End Date</label>
            <input
              class="form-control input-big col-sm-8"
              type="datetime-local"
              id="endTime"
              value={state.service.endTime.slice(0, 16)}
            ></input>
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2">Timeout</label>
            <input
              class="form-control input-medium col-sm-8"
              id="timeoutPeriod"
              value={state.service.timeoutPeriod}
            />
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2">Run Every</label>

            <input
              class="form-control input-small col-sm-1"
              id="runIntervalValue"
              value={state.service.runIntervalValue}
            ></input>
            <select
              class="form-control input-small col-sm-7"
              id="runIntervalType"
              name="runIntervalType"
              value={state.service.runIntervalType}
            >
              <option >Select Frequency</option>

              {auditFrequency.map(
                ({ systemCodeId, systemCodeValueValue, systemCodeValueId }) => (
                  <option key={systemCodeValueId} value={systemCodeId}>
                    {systemCodeValueValue}{" "}
                  </option>
                )
              )}
            </select>
          </div>
          <div class="form-group row">
            <label value="Connector" class="ContentTitle col-sm-2">
              Connector
            </label>
            <select
              id="dataSources"
              class="form-control col-sm-8"
              value={state.service.contentInputSourceId}
            >
              <option>Select A Connector</option>
              {connectors.map(
                ({ contentInputSourceId, contentInputSourceName }, index) => (
                  <option key={index} value={contentInputSourceId}>
                    {contentInputSourceName}
                  </option>
                )
              )}
            </select>
          </div>
          <div class="form-group row">
            <label value="Status" class="ContentTitle col-sm-2">
              Status
            </label>
            <select  id="statusCode" class="form-control input-big col-sm-8">
            <option >Select Status</option>
              {status.map(
                ({ systemCodeId, systemCodeValueValue, systemCodeValueId }) => (
                  <option key={systemCodeValueId} value={systemCodeId}>
                    {systemCodeValueValue}{" "}
                  </option>
                )
              )}
            </select>
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2" value="Task Run Date">
              Task Run Date
            </label>
            <input
              class="form-control input-big col-sm-8"
              type="datetime-local"
              id="taskDate"
              name="taskDate"
              value={state.service.taskDate.slice(0, 16)}
            ></input>
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2" value="Task Run Time">
              Task Run Time
            </label>
            <input
              class="form-control input-big col-sm-8"
              type="datetime-local"
              id="taskTime"
              name="taskTime"
              value={state.service.taskTime.slice(0, 16)}
            ></input>
          </div>

          <hr />
          <center>
            <button
              class="btn btn-info"
              value="Schedule This Service"
              //   onClick={handleOnScheduleService}
            >
              Schedule This Service
            </button>
            &nbsp;
            <button
              class="btn btn-primary"
              value="Start"
              //   onClick={handleOnStartService}
            >
              Start
            </button>
            &nbsp;
            <button
              class="btn btn-warning"
              value="Stop"
              //   onClick={handleOnStopService}
            >
              Stop
            </button>
            &nbsp;
            <button
              value="Delete"
              class="btn btn-danger"
              //   onClick={handleOnDelete}
            >
              Delete
            </button>
            &nbsp;
            <button
              value="Save"
              class="btn btn-success"
              //   onClick={this.handleOnSave}
            >
              Save
            </button>
            &nbsp;
            <button
              value="Clear"
              class="btn btn-secondary"
              //   onClick={handleOnClear}
            >
              Clear
            </button>
          </center>
        </div>
      </form>
    </div>
  );
};

export default ServiceScheduler;

// export default class ServiceScheduler extends Component {

// 	constructor(props)
// 	{
// 		console.log(props);
// 		super(props);
// 		this.state = {
//     			services: [],
// 			service : {
// 					"scanJobId": 0,
// 					  "scanJobName": "",
// 					  "scanJobDescription": "",
// 					  "startTime": new Date(),
// 					  "endTime": new Date(),
// 					  "contentInputSourceId": 0,
// 					  "securityContextId": 0,
// 					  "createUser": 0,
// 					  "createDate": new Date(),
// 					  "lastUpdateUser": 0,
// 					  "lastUpdateDate": new Date(),
// 					  "timeoutPeriod": 0,
// 					  "taskTime": new Date(),
// 					  "taskDate": new Date(),
// 					  "runIntervalValue": 0,
// 					  "runDays": "",
// 					  "runIntervalType": ""
// 			},
// 			currentServiceId : 0
//   		};
// 		this.handleOnLoad = this.handleOnLoad.bind(this);
//         this.handleOnChange = this.handleOnChange.bind(this);
// 	}
// 	componentDidMount()
// 	{
// 		this.handleOnLoad();
// 	}
// 	const handleOnLoad = () => {
//       		axios.get(`http://os-dev.origosoftware.com:3000/scan-jobs/`).then(res => {
//       		const services = res.data;
//       		console.log(services);
//       		this.setState({ "services" : services });
//       		console.log(res.data[0]);
// 		});

// 		if (this.state.currentServiceId > 0)
// 		{
// 	      		axios.get(`http://os-dev.origosoftware.com:3000/scan-jobs/${this.state.currentServiceId}`).then(res => {
//       			const service = res.data;
//       			console.log(service);
//       			this.setState({ service : service });
// 			});
// 		}
// 	}
// 	handleOnClear = () => {
// 	}
// 	handleOnSave = () => {
// 	}
// 	handleOnDelete = () => {
// 	}
// 	handleOnStartService = () => {
// 	}
// 	handleOnStopService = () => {
// 	}
// 	handleOnScheduleService = () => {
// 	}
// 	function handleOnChange(event) {
//         this.setState({ currentServiceId: event.target.key });
//         console.log('set new service id = ' + this.state.currentServiceId);
// 		if (this.state.currentServiceId > 0)
// 		{
// 	      		axios.get(`http://os-dev.origosoftware.com:3000/scan-jobs/${this.state.currentServiceId}`).then(res => {
//       			const service = res.data;
//       			console.log(service);
//       			this.setState({ service : service });
// 			});
// 		}
// 	}

// 	render(){

//         return (

// 	<form>
// 		<div class="container-fluid">
// 		<h4>Data Service Scheduler</h4>
// 		<hr />
// 		<div class="form-group row">
// 			<label class="ContentTitle col-sm-2">Select Service</label>
// 			<select class="form-control input-big col-sm-8" id="scanJobId" onChange={this.handleOnChange}>
// 			{this.state.services.map((service) => <option key={service.scanJobId} value={service.scanJobId}>{service.scanJobName}</option>)}
// 			</select>
// 		</div>
// 			<hr />
// 		<div class="form-group row">
// 			<label class="ContentTitle col-sm-2" value="Name: ">Name
// 			</label>
// 					<input id="name" class="form-control input-big col-sm-8" value={this.state.service.scanJobName }></input>
// 		</div>
// 		<div class="form-group row">
// 			<label class="ContentTitle col-sm-2" value="Service ID : ">Service Id</label>
// 			<input id="selectedServiceId" readonly="true" class="form-control col-sm-8" value={this.state.service.scanJobId } />
// 		</div>
// 		<div class="form-group row">
// 			<label class="ContentTitle col-sm-2" value="Description: ">
// 				Description
// 			</label>
// 			<textArea id="description"   value={this.state.service.scanJobDescription } class="form-control input-big col-sm-8" >
// 			</textArea>
// 		</div>
// 		<div class="form-group row">
// 			<label class="ContentTitle col-sm-2" >Begin Date</label>
// 			<input class="form-control input-big col-sm-8"  type="datetime-local" id="startTime" value={this.state.service.startTime }>
// 			</input>
// 		</div>
// 		<div class="form-group row">
// 						<label class="ContentTitle col-sm-2" >End Date</label>
// 						<input  class="form-control input-big col-sm-8"  type="datetime-local" id="endTime" value={this.state.service.endTime }>
// 						</input>
// 		</div>
// 		<div class="form-group row">

// 						<label class="ContentTitle col-sm-2" >Timeout</label>
// 						<input class="form-control input-medium col-sm-8" id="timeoutPeriod"  value={this.state.service.timeoutPeriod } />
// 		</div>
// 		<div class="form-group row">
//                 <label class="ContentTitle col-sm-2">Run Every</label>

//                 <input class="form-control input-small col-sm-1" id="runIntervalValue"  value={this.state.service.runIntervalValue}></input>
//                 <select class="form-control input-small col-sm-7" id="runIntervalType" name="runIntervalType" value={this.state.service.runIntervalType}>
// 	            </select>

// 		</div>
// 		<div class="form-group row">

// 				<label value="Connector" class="ContentTitle col-sm-2" >Connector</label>
// 				<select id="dataSources" class="form-control col-sm-8" value={this.state.service.contentInputSourceId }>
// 				</select>
// 		</div>
// 		<div class="form-group row">
// 				<label value="Status" class="ContentTitle col-sm-2">Status</label>
// 				<select id="statusCode" class="form-control input-big col-sm-8">
// 				</select>
// 		</div>
// 		<div class="form-group row">
//              <label class="ContentTitle col-sm-2"  value="Task Run Date">Task Run Date</label>
//              <input class="form-control input-big col-sm-8" type='date'  id="taskDate" name="taskDate" value={this.state.service.taskDate } >
//              </input>
// 		</div>
// 		<div class="form-group row">
//              <label class="ContentTitle col-sm-2"  value="Task Run Time">Task Run Time</label>
//              <input class="form-control input-big col-sm-8" type='time' id="taskTime" name="taskTime" value={this.state.service.taskTime} >
//              </input>

// 		</div>

// 		<hr />
// 		<center>
// 				<button class="btn btn-info" value="Schedule This Service" onClick={this.handleOnScheduleService}>Schedule This Service</button>&nbsp;
// 				<button class="btn btn-primary" value="Start" onClick={this.handleOnStartService}>Start</button>&nbsp;
// 				<button class="btn btn-warning" value="Stop" onClick={this.handleOnStopService}>Stop</button>&nbsp;
//    				<button value="Delete"  class="btn btn-danger" onClick={this.handleOnDelete}>Delete</button>&nbsp;
//    				<button value="Save"  class="btn btn-success" onClick={this.handleOnSave}>Save</button>&nbsp;
//    				<button value="Clear"  class="btn btn-secondary"  onClick={this.handleOnClear }>Clear</button>
// 		</center>
// 		</div>
//     	</form>
// );
// }
// }
