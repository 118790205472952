import React from 'react'

export const Loading = () => {
  return (
    <div  style={{width:"100vw",height:"100vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
      	<div className="spinner">
      	<img src={require('../assets/images/loading.gif')} alt="loading"/>
    	</div>
    </div>
    
  )
}