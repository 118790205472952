import React, {Component} from 'react';
import {  Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { globalConfig } from "../config";

const querystring = require('querystring');


export default class Services extends Component {

	constructor(props)
	{
		console.log(props);
    super(props);
  }

  state = {
    services: []
  };

	componentDidMount() 
	{
		this.handleOnLoad();
	}


	handleOnLoad = () => {
		console.log('getting services');
		try
		{		
			let url = `${globalConfig.apiDatahubUrl}/scan-jobs/`;
    	  	axios.get(url, globalConfig.hardcoded_header).then(res => {
      		const services = res.data;
      		this.setState({ services });
		});
		}
		catch(error)
		{
			console.log(error);
		}	
	}
	
	render(){
    return (
		<form>

		<div class="container-fluid">
		{/*
		<div class="container-fluid">
		          <Link
            to="/services"
            className="btn btn-outline-secondary mt-2 mb-3 "
          >
            Back
          </Link>
		*/}
	<div className="d-flex flex-row justify-content-between mb-3 mt-3">
			  <h4>Services</h4>
            <Link to="/create-service" className="btn-success btn">
              Create Service
            </Link>
      </div>

      <div className="form-group row">
      	<table class="table table-bordered table-striped">
      	<thead>
      	<tr>
      	<th scope="col">#</th>
      	<th scope="col">Name</th>
     	<th scope="col">Description</th>
     	<th scope="col">Action</th>
    	</tr>
  		</thead>
  		<tbody>

          {this.state.services.map( ( {scanJobName, scanJobDescription, scanJobId} ) => {
  					  return <tr>
  					  
                        <td class="ContentTitle">
                          <Link
                            to={{
                              pathname: `/manage-service/${scanJobId}`,
                            }}
                          >
                            {scanJobId}{" "}
                          </Link>
                        </td>
                        <td class="ContentTitle">
                          <Link
                            to={{
                              pathname: `/manage-service/${scanJobId}`,
                            }}
                          >
                            {scanJobName}{" "}
                          </Link>
                        </td>
                        <td class="ContentTitle">
                          <Link
                            to={{
                              pathname: `/manage-service/${scanJobId}`,
                            }}
                          >
                            {scanJobDescription}{" "}
                          </Link>
						</td>	

						<td>
							<button class="btn btn-success">Start</button>&nbsp; 
							<button class="btn btn-warning">Stop</button>&nbsp; 
							<button class="btn btn-primary">Transform</button>&nbsp; 
  					  		<button class="btn btn-danger">Delete</button>
  					  	</td>
  					  	</tr>
					}) 
				}
        </tbody>
        </table>
      </div>
      </div>
		</form>		
	  );
	}
}