import React, {Component} from 'react';
import {  Link } from 'react-router-dom';
import axios from 'axios';
const querystring = require('querystring');

export default class FindConnectors extends Component {

	constructor(props)
	{
		console.log(props);
    super(props);
  }

  state = {
    connectors: []
  };

	componentDidMount() 
	{
		this.handleOnLoad();
	}

	handleOnLoad = () => {
      axios.get(`http://os-dev.origosoftware.com:3000/content-input-sources/`).then(res => {
      const connectors = res.data;
      console.log(connectors);
      this.setState({ connectors });
      console.log(res.data[0]);
		});
		
	}
	
	render(){
    return (
		<form>
      <div className="text-right">
			  <h4>Connectors</h4>
      </div>
      <div className="form-group row">
      	<table className="table">
        <label className="ContentTitle" value="">
          {this.state.connectors.map( ( {contentSourceDescr, contentInputSourceId, contentInputSourceName} ) => {
              return <tr><td>{contentInputSourceId}</td><td>{contentSourceDescr} </td><td> {contentInputSourceName}</td>
                  <td><button class="btn blue">Delete</button></td>
              </tr>
					}) 
				}
        </label>
        </table>
      </div>
		</form>		
	  );
	}
}