import React, { lazy, Suspense, useContext } from 'react'
// import { Switch, Route,  } from 'react-router-dom'
//import React, { useState, useRef, useMemo } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../HomePage";
import CreateConnector from "../CreateConnector";
import CreateService from "../CreateService";
import CreateTransformer from "../CreateTransformer";
import ManageTransformer from "../ManageTransformer";
import ManageConnector from "../ManageConnector";
import ManageService from "../ManageService";
import ServiceScheduler from "../ServiceScheduler";
import Connectors from "../Connectors";
import Services from "../Services";
import Transformers from "../Transformers";
import FindConnectors from "../FindConnectors";
import FindServices from "../FindServices";
import FindTransformers from "../FindTransformers";


const RouteConfigs = () =>
{

	
	return (

	    <BrowserRouter>
  			<Routes>
	 			<Route path="/" exact element={<HomePage />} >
	 			</Route>
		      	<Route path="/gazelle-neo" element={<HomePage />} ></Route>
    	  		<Route path="/connectors" element={<Connectors />}></Route>
      			<Route path="/services" element={<FindServices />}></Route>
	      		<Route path="/transformers" element={<FindTransformers />}></Route>
    	  		<Route path="/create-connector" element={<CreateConnector />}></Route>
      			<Route path="/create-service" element={<CreateService />}></Route>
      			<Route path="/create-transformer" element={<CreateTransformer />}></Route>
	      		<Route path="/find-connectors" element={<FindConnectors />}></Route>
    	  		<Route path="/find-services" element={<FindServices />}></Route>
      			<Route path="/find-transformers" element={<FindTransformers />}></Route>
      			<Route path="/manage-connector/:id" element={<ManageConnector />}></Route>
      			<Route path="/manage-service/:id" element={<ManageService />}></Route>
      			<Route path="/manage-transformer/:id" element={<ManageTransformer />}></Route>
      			<Route path="/service-scheduler" element={<ServiceScheduler />}></Route>  			
      			<Route path="/logout" element={<logout />}></Route>  			
  			</Routes>
    	</BrowserRouter>
	);


}

export default RouteConfigs;
