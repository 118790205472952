import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { globalConfig } from "../config";

import { SelectListComponent } from "shared-components";
const querystring = require("querystring");

const CreateService = () => {
  const [message, setMessage] = useState("");
  const [connectors, setConnectors] = useState([]);
  const [auditFrequency, setAuditFrequency] = useState([]);

  const [state, setState] = useState({
    scanJobId: 0,
    scanJobName: "",
    scanJobDescription: "",
    startTime: new Date().toISOString(),
    endTime: new Date().toISOString(),
    contentInputSourceId: 0,
    securityContextId: 0,
    createUser: 0,
    createDate: new Date().toISOString(),
    lastUpdateUser: 0,
    lastUpdateDate: new Date().toISOString(),
    timeoutPeriod: 0,
    taskTime: new Date().toISOString(),
    taskDate: new Date().toISOString(),
    runIntervalValue: 0,
    runDays: "",
    runIntervalType: "",
  });

  useEffect(() => {
    handleOnLoad();
  }, []);

  const handleOnLoad = () => {
    handleClear();
    axios
      .get(
        `${globalConfig.apiDatahubUrl}/content-input-sources`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const connectors = res.data;
        console.log(connectors);
        setConnectors(connectors);
      });

    axios
      .get(
        `${globalConfig.systemCodesUrl}/AUDIT_FREQUENCY`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const auditFrequency = res.data;
        console.log(auditFrequency);
        setAuditFrequency(auditFrequency);
      });
  };
  function handleClear() {
    setState({
      scanJobId: 0,
      scanJobName: "",
      scanJobDescription: "",
      startTime: new Date().toISOString(),
      endTime: new Date().toISOString(),
      contentInputSourceId: 0,
      securityContextId: 0,
      createUser: 0,
      createDate: new Date().toISOString(),
      lastUpdateUser: 0,
      lastUpdateDate: new Date().toISOString(),
      timeoutPeriod: 0,
      taskTime: new Date().toISOString(),
      taskDate: new Date().toISOString(),
      runIntervalValue: 0,
      runDays: "",
      runIntervalType: "",
    });
  }

  function handleDateChanges(event) {
    console.log(event);
    var target = event.target;
    console.log(target.id + ":" + target.value);
    setState({
      ...state,
      [target.id]: target.value + ":00.00Z",
    });
    console.log(state);
  }

  function handleChange(event) {
    console.log(event);
    var target = event.target;
    console.log(target.id + ":" + target.value);
    //isNaN(target.value) ? target.value : parseInt(target.value)
    //if (target.id === 'runIntervalValue' || target.id === 'timeoutPeriod')
    //{
    //	target.value = Number(target.value);
    //}
    setState({
      ...state,
      [target.id]: isNaN(target.value) ? target.value : parseInt(target.value),
    });
    console.log(state);
  }
  function handleCreate(event) {
    console.log("handling submit 1");

    event.preventDefault();
    console.log("handling submit");
    console.log(state.startTime + ":00.000Z");
    setState({
      ...state,
      runIntervalValue: Number(state.runIntervalValue),
      timeoutPeriod: Number(state.timeoutPeriod),
    });

    var url = `${globalConfig.apiDatahubUrl}/scan-jobs`;
    var idUrl = `${globalConfig.apiMainUrl}/next-id-generator/get-next/scan-jobs/scanJobId`;

    axios.get(idUrl, globalConfig.hardcoded_header).then((response) => {
      state.scanJobId = response.data["nextId"];

      console.log("request object = " + JSON.stringify(state));
      axios
        .post(url, state, globalConfig.hardcoded_header)
        .then((response2) => {
          console.log("response.data");
          if (response2.status === 200) {
            setMessage("Success");
            window.location.replace("/services");
          } else {
            setMessage("Failed");
          }
          console.log(message);
          //
          event.preventDefault();
        })
        .catch((err) => {
          console.log("error - " + err.message);
        });
      console.log("message info - " + message);

      handleClear();
    });
  }

  return (
    <div class="container-fluid">
      <form>
        <div class="container-fluid">
          <Link to="/services" className="btn btn-outline-secondary mt-2 mb-3">
            Back
          </Link>

          <h4>Create A Service</h4>
          <hr />
          <div class="form-group row">
            <label class="ContentTitle col-sm-2" value="Name: ">
              Name
            </label>
            <input
              id="scanJobName"
              class="form-control input-xlarge col-sm-8"
              value={state.scanJobName}
              onChange={handleChange}
            />
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2" value="Description: ">
              Description
            </label>
            <textarea
              id="scanJobDescription"
              value={state.scanJobDescription}
              onChange={handleChange}
              class="form-control input-xlarge col-sm-8"
            ></textarea>
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2" value="Begin Date ">
              Begin Date
            </label>
            <input
              class="form-control input-xlarge col-sm-8 date-picker"
              value={state.startTime.slice(0, 16)}
              onChange={handleDateChanges}
              id="startTime"
              type="datetime-local"
              renderAsPopup="true"
            ></input>
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2" value="End Date ">
              End Date
            </label>
            <input
              class="form-control input-xlarge col-sm-8 date-picker"
              value={state.endTime.slice(0, 16)}
              onChange={handleDateChanges}
              id="endTime"
              type="datetime-local"
              renderAsPopup="true"
            ></input>
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2" value="Timeout: ">
              Timeout
            </label>
            <input
              class="form-control input-xlarge col-sm-8"
              id="timeoutPeriod"
              type="number"
              value={state.timeoutPeriod}
              onChange={handleChange}
            />
          </div>
          <div class="form-group row">
            <label value="Connector" class="ContentTitle col-sm-2">
              Connector
            </label>
            {/* <SelectListComponent IdName="contentInputSourceId" onChange={this.handleChangeConnector} entityName ={"content-input-sources"}
						valueFilterColumnName = {"contentInputSourceId"}  displayColumnName ={"contentInputSourceName"}
						keyColumnName ={"contentInputSourceId"} valueId={this.state.contentSourceType} /> */}

            <select
              id="contentInputSourceId"
              class="form-control input-small col-sm-8"
              value={state.contentInputSourceId}
              onChange={handleChange}
            >
              <option>Select A Connector</option>
              {connectors.map(
                ({ contentInputSourceId, contentInputSourceName }, index) => (
                  <option key={index} value={contentInputSourceId}>
                    {contentInputSourceName}
                  </option>
                )
              )}
            </select>
          </div>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2" value="Task Run Date">
              Task Run Date
            </label>
            <input
              class="form-control input-xlarge col-sm-8 date-picker"
              id="taskDate"
              type="datetime-local"
              name="taskDate"
              onChange={handleDateChanges}
              value={state.taskDate.slice(0, 16)}
            ></input>
          </div>
        </div>
        <div class="form-group row">
          <label class="ContentTitle col-sm-2" value="Task Run Time">
            Task Run Time
          </label>
          <input
            class="form-control input-xlarge col-sm-8"
            id="taskTime"
            type="datetime-local"
            name="taskTime"
            onChange={handleDateChanges}
            value={state.taskTime.slice(0, 16)}
          ></input>
        </div>
        <div class="form-group row">
          <label class="ContentTitle col-sm-2" value="Run Every">
            Run Every
          </label>
          <input
            class="form-control input-small col-sm-1"
            id="runIntervalValue"
            value={state.runIntervalValue}
            onChange={handleChange}
          ></input>
          {/* <SelectListComponent IdName="contentTargetType" onChange={this.handleChange} entityName ={"system-code-values"}
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"245"} displayColumnName ={"systemCodeValueValue"}
				keyColumnName ={"systemCodeValueId"}/> */}

          <select
            class="form-control input-xlarge col-sm-7"
            id="runIntervalTypeCreateService"
            name="runIntervalTypeCreateService"
            value={state.runIntervalType}
          >
            {/* <option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('AUDIT_FREQUENCY')}" /> */}
            {auditFrequency.map(
              ({ systemCodeId, systemCodeValueValue, systemCodeValueId }) => (
                <option key={systemCodeValueId} value={systemCodeId}>
                  {systemCodeValueValue}{" "}
                </option>
              )
            )}
          </select>
        </div>

        <hr />
        <div className="container-fluid">
          <div class="form-group row">
            &nbsp;&nbsp;
            <button
              value="Create"
              class="btn btn-success"
              onClick={handleCreate}
            >
              Create
            </button>
            &nbsp;
            <button value="Clear" class="btn btn-primary" onClick={handleClear}>
              Clear
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateService;
