import React from 'react';
import { useAuth0, Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query'
import RouteConfigs from './components/routes/RouteConfigs';
import { Auth } from "./components/Auth/Auth";
import { Loading } from "./components/Loading";
import ErrorBoundary from "./components/ErrorBoundary";
import { ToastProvider } from 'react-toast-notifications'
import { createTheme, ThemeProvider } from '@mui/material/styles'


function App() {

	const { logout } = useAuth0()
	const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

//   	console.log("authenticated - " + isAuthenticated ,  "isLoading - " + isLoading);

  return (
  	<>	    
		    <div className="App">
      		<header className="App-header">
	          {isLoading ? (
    	        <Loading />
        	  ) : !isAuthenticated ? (
	            	loginWithRedirect()
          		) : (
      			<Auth>
	          			<ToastProvider>
    	     		 	<ErrorBoundary>
        	  			<RouteConfigs />
    					</ErrorBoundary>
						</ToastProvider>
				</Auth>
	        	)}
      		</header> 
    		</div>
    </>
  );
}

export default App;