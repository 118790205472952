import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { globalConfig } from "../config";

import axios from "axios";
import { SelectListComponent } from "shared-components";
const querystring = require("querystring");

const CreateConnector = () => {
  const [message, setMessage] = useState("");
  const [contentInputTypes, setContentInputTypes] = useState([]);

  const [state, setState] = useState({
    contentInputSourceId: 0,
    contentInputSourceName: "",
    contentSourceDescr: "",
    contentSourceUrl: "",
    contentSourceType: 0,
    sourceIconType: 0,
    transformerFlag: 0,
    transformerId: 0,
    contentTargetUrl: "",
    contentTargetType: 0,
    targetIconType: 0,
    timeoutPeriod: 0,
    securityContextId: 0,
    lastUpdateUser: 0,
    lastUpdateDate: new Date(),
    sourceUsername: "",
    sourcePassword: "",
    targetUsername: "",
    targetPassword: "",
  });
  const [transformers, setTransformers] = useState([]);

  useEffect(() => {
    handleOnLoad();
  }, []);

  //handling changes
  function handleChange(event) {
    console.log(event);
    var target = event.target;
    console.log(target.id + ":" + target.value);
    setState({
      ...state,
      [target.id]: isNaN(target.value) ? target.value : parseInt(target.value),
    });

    console.log(state);
  }

  //handle create submit
  function handleSubmit(event) {
    console.log("handling submit 1");

    event.preventDefault();
    console.log("handling submit");

    var url = `${globalConfig.apiDatahubUrl}/content-input-sources`;
    var idUrl = `${globalConfig.apiMainUrl}/next-id-generator/get-next/content-input-sources/contentInputSourceId`;

    axios.get(idUrl, globalConfig.hardcoded_header).then((response) => {
      state.contentInputSourceId = response.data["nextId"];

      //console.log("new category id = " + JSON.stringify(state.contentCategoriesObject.contentCategoryId));

      console.log("request object = " + JSON.stringify(state));
      axios
        .post(url, state, globalConfig.hardcoded_header)
        .then((response2) => {
          console.log("response.data");
          console.log(response2.status + ":" + response2.data.accountId);
          // && response2.data.contentFacadeId === state.contentFacadeId
          if (response2.status === 200) {
            setMessage("Success");
            window.location.replace("/connectors");
          } else {
            setMessage("Failed");
          }
          console.log(message);
          //
          event.preventDefault();
          //response.send(message);
        })
        .catch((err) => {
          console.log("error - " + err);
        });
      console.log("message info - " + message);

      handleClear();
    });
  }

  //handle clear fields
  function handleClear() {
    setState({
      contentInputSourceId: 0,
      contentInputSourceName: "",
      contentSourceDescr: "",
      contentSourceUrl: "",
      contentSourceType: 0,
      sourceIconType: 0,
      transformerFlag: 0,
      transformerId: 0,
      contentTargetUrl: "",
      contentTargetType: 0,
      targetIconType: 0,
      timeoutPeriod: 0,
      securityContextId: 0,
      lastUpdateUser: 0,
      lastUpdateDate: new Date(),
      sourceUsername: "",
      sourcePassword: "",
      targetUsername: "",
      targetPassword: "",
    });
  }

  //clear fields on load

  const handleOnLoad = () => {
    handleClear();
    axios
      .get(
        `${globalConfig.apiDatahubUrl}/transformations/`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const transformations = res.data;
        console.log(transformations);
        setTransformers(transformations);
      });

    axios
      .get(
        `${globalConfig.systemCodesUrl}/CONTENT_INPUT_SOURCE_TYPE`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const sourceTypes = res.data;
        console.log(sourceTypes);
        setContentInputTypes(sourceTypes);
      });
  };

  return (
    <div class="container-fluid">
      <form>
        <div class="container-fluid">
          <Link
            to="/connectors"
            className="btn btn-outline-secondary mt-2 mb-3 "
          >
            Back
          </Link>
          <h4>Create A Connector</h4>
          <hr />
          <h4>Source</h4>

          <div columns="2">
            <div class="form-group row">
              <label class="ContentTitle col-sm-2" value="Name: ">
                Name
              </label>
              <input
                class="form-control input-xlarge col-sm-8"
                id="contentInputSourceName"
                value={state.contentInputSourceName}
                onChange={handleChange}
              />
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Description</label>
              <input
                class="form-control input-xlarge col-sm-8"
                id="contentSourceDescr"
                value={state.contentSourceDescr}
                onChange={handleChange}
              />
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Source URL</label>
              <input
                class="form-control input-xlarge col-sm-8"
                id="contentSourceUrl"
                value={state.contentSourceUrl}
                onChange={handleChange}
              />
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Source Type</label>
              {/* <SelectListComponent IdName="contentTargetType" onChange={this.handleChange} entityName ={"system-code-values"} 
    				valueFilterColumnName = {"systemCodeId"} valueFilter = {"33"} displayColumnName ={"systemCodeValueValue"} 
    				keyColumnName ={"systemCodeValueId"}/> */}

              <select
                class="form-control input-xlarge col-sm-8"
                id="contentTargetType"
                value={state.contentTargetType}
                onChange={handleChange}
              >
                {/* <option value="#{ombTransformationForm.transformations}" />
                <message for="contentTargetType" /> */}

                {contentInputTypes.map(
                  ({
                    systemCodeId,
                    systemCodeValueValue,
                    systemCodeValueId,
                  }) => (
                    <option key={systemCodeValueId} value={systemCodeId}>
                      {systemCodeValueValue}{" "}
                    </option>
                  )
                )}
              </select>
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Source User Name</label>
              <input
                class="form-control input-medium col-sm-8"
                id="sourceUsername"
                value={state.sourceUsername}
                onChange={handleChange}
              />
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Source Password</label>
              <input
                type="password"
                class="form-control input-medium col-sm-8"
                id="sourcePassword"
                value={state.sourcePassword}
                onChange={handleChange}
              />
            </div>
          </div>

          <hr />
          <h4>Target</h4>

          <div columns="2">
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Target URL</label>
              <input
                class="form-control input-xlarge col-sm-8"
                id="contentTargetUrl"
                value={state.contentTargetUrl}
                onChange={handleChange}
              />
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Target User Name</label>
              <input
                class="form-control input-xlarge col-sm-8"
                id="targetUsername"
                value={state.targetUsername}
                onChange={handleChange}
              />
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Target Password</label>
              <input
                type="password"
                class="form-control input-xlarge col-sm-8"
                id="targetPassword"
                value={state.targetPassword}
                onChange={handleChange}
              />
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Target Type</label>
              {/* <SelectListComponent IdName="contentTargetType" onChange={this.handleChange} entityName ={"system-code-values"} 
    				valueFilterColumnName = {"systemCodeId"} valueFilter = {"33"} displayColumnName ={"systemCodeValueValue"} 
    				keyColumnName ={"systemCodeValueId"}/> */}

              <select
                class="form-control input-xlarge col-sm-8"
                id="contentTargetType"
                value={state.contentTargetType}
                onChange={handleChange}
              >
                {/* <option value="#{ombTransformationForm.transformations}" />
                <message for="contentTargetType" /> */}
                {contentInputTypes.map(
                  ({
                    systemCodeId,
                    systemCodeValueValue,
                    systemCodeValueId,
                  }) => (
                    <option key={systemCodeValueId} value={systemCodeId}>
                      {systemCodeValueValue}{" "}
                    </option>
                  )
                )}
              </select>
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Core Transformation</label>
              {/* <SelectListComponent IdName="transformerId" onChange={this.handleChange} entityName ={"transformations"} 
    						keyColumnName={"transformationId"} displayColumnName ={"transformationName"} 
    						valueFilterColumnName={"transformationId"} valueId={this.state.transformerId} /> */}

              <select
                class="form-control input-xlarge col-sm-8"
                id="transformerId"
                value={state.transformerId}
                onChange={handleChange}
              >
                {/* <option value="#{ombTransformationForm.transformations}" />
              <message for="transformerId" /> */}
                <option>Select A Transformer</option>
                {transformers.map(
                  ({ transformationName, transformationId }, index) => (
                    <option key={index} value={transformationId}>
                      {transformationName}
                    </option>
                  )
                )}
              </select>
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Transformer On</label>
              <input
                type="checkbox"
                id="transformerFlag"
                value={state.transformerFlag}
                onChange={handleChange}
              />
            </div>
          </div>

          <hr />
          <div className="container-fluid">
            <div class="form-group row ">
              <button
                value="Create"
                class="btn btn-success"
                onClick={handleSubmit}
              >
                Create
              </button>
              &nbsp;
              <button
                value="Clear"
                class="btn btn-primary"
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateConnector;
