import React from 'react';
import ReactDOM from 'react-dom';
import {Auth0Provider} from '@auth0/auth0-react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { globalConfig } from './config'

// 			audience: "https://origotelehealth.auth0.com/api/v2",
// 			scope: "read:current_user read:current_user_metadata",
// 		domain={globalConfig.auth0.domain}
// 		clientId={globalConfig.auth0.clientId}

ReactDOM.render(
	<Auth0Provider
	    domain= 'origotelehealth.auth0.com'
    	clientId= 'nSXQIFBzQGGUZlWTDeNK7gJ65MnNRlmb'
		authorizationParams={{
			redirect_uri: window.location.origin,
		}}
	>
	<App />
	</Auth0Provider>, 
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
