import React, { Component } from "react";
import { Link } from "react-router-dom";
const querystring = require("querystring");

const HomePage = () => {
//   constructor(props) {
//     console.log(props);
//     super(props);
//   }
//  render() {
    return (
    	<>
        <form>
            <div class="container-fluid">
            <div class="container">
                    <div class="row">
                        <div class="col-md">
                            <h4>Connectors</h4>

        <p>
          <a href="/connectors" className="ContentTitle">
            Connectors
          </a>
        </p>
        <p>
          <a href="/manage-connector" className="ContentTitle">
            Manage Connectors
          </a>
        </p>
        <p>
          <a href="/create-connector" className="ContentTitle">
            Create A Connector
          </a>
                            </p>
                        </div>

                        <div class="col-md">
                            <h4>Services</h4>
                            <p>
                                <a href="/services/" className="ContentTitle">
                                    Services
          </a>
                            </p>
                            <p>
                                <a href="/manage-service/" className="ContentTitle">
                                    Manage Services
          </a>
                            </p>
                            <p>
                                <a href="/create-service/" className="ContentTitle">
                                    Create A Service
          </a>
                            </p>
                        </div>

                        <div class="col-md">
                            <h4>Transformers</h4>
                            <p>
                                <a href="/transformers/" className="ContentTitle">
                                    Transformers
          </a>
                            </p>
                            <p>
                                <a href="/manage-transformer/" className="ContentTitle">
                                    Manage Transformers
          </a>
                            </p>
                            <p>
                                <a href="/create-transformer/" className="ContentTitle">
                                    Create A Transformer
          </a>
                            </p>
                        </div>
                        <div class="col-md">
                            <h4>Jobs</h4>
                            <p>
                                <a href="service-scheduler" className="ContentTitle">
                                    Service Scheduler
          </a>
                            </p>
                        </div>
                </div>
                </div>
            </div>

            


      </form>
      </>
    );
//  }
}

export default HomePage;