import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { globalConfig } from "../config";
import axios from "axios";
import { SelectListComponent } from "shared-components";
const querystring = require("querystring");

const ManageTransformer = ({ location }) => {
	const { id } = useParams();
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState([]);
  console.log('id - ' + id);
  const [state, setState] = useState({
    transformationId: id,
    transformationName: "",
    resourceFile: "",
    className: "",
    description: "",
    transformationVersion: "",
    statusCode: 0,
    securityContextId: 0,
    createUser: 0,
    createDate: new Date(),
    lastUpdateUser: 0,
    lastUpdateDate: new Date(),
    parameters: "",
    transformationTypeCode: 0,
  });

  useEffect(() => {
    handleOnLoad();
  }, []);
  const handleOnLoad = () => {
    axios
      .get(
        `${globalConfig.apiDatahubUrl}/transformations/${id}`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const transformation = res.data;
        console.log(transformation);
        setState(transformation);
      });

      axios
      .get(
        `${globalConfig.systemCodesUrl}/TRANSFORMATION_STATUS`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const transformation_status = res.data;
        console.log(transformation_status );
        setStatus(transformation_status );
      });
  };
  function handleClear() {
    state = {
      transformationId: 0,
      transformationName: "",
      resourceFile: "",
      className: "",
      description: "",
      transformationVersion: "",
      statusCode: 0,
      securityContextId: 0,
      createUser: 0,
      createDate: new Date(),
      lastUpdateUser: 0,
      lastUpdateDate: new Date(),
      parameters: "",
      transformationTypeCode: 0,
    };
  }
  function handleChange(event) {
    console.log(event);
    var target = event.target;
    console.log(target.id + ":" + target.value);

    setState({ ...state, [target.id]: target.value });
    console.log(state);
  }

  function handleSave(event) {
    console.log("handling save 1");

    event.preventDefault();
    console.log("handling save");

    var url = `${globalConfig.apiDatahubUrl}/transformations/${state.transformationId}`;

    axios
      .patch(url, state, globalConfig.hardcoded_header)
      .then((response2) => {
        console.log("response.data");
        if (response2.status === 200) {
          setMessage("Success");
        } else {
          setMessage("Failed");
        }
        console.log(message);
        //
        event.preventDefault();
      })
      .catch((err) => {
        console.log("error - " + err);
      });
    console.log("message info - " + message);

    handleClear();
    setInterval(() => {
      window.location.reload();
    }, 1000);
  }

  function handleDelete(event) {
    event.preventDefault();
    //alert('deleting service with id = ' + state.scanJobId);
    axios
      .delete(
        `${globalConfig.apiDatahubUrl}/transformations/${state.transformationId}`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const deleteResponse = res.data;
        console.log(deleteResponse);
        window.location.replace = "/transformers";
      });
    handleClear();
  }
  return (
    <div class="container-fluid">

    <form>
      <div class="container-fluid">
        <Link
          to="/transformers"
          className="btn btn-outline-secondary mt-2 mb-3 "
        >
          Back
        </Link>
        <h4>Manage Transformer</h4>
        <hr />

        <div class="form-group row">
          <label class="ContentTitle col-sm-2" value="Transformer Name">
            Transformer Name
          </label>
          <input
            class="form-control input-xlarge col-sm-8"
            id="transformationName"
            value={state.transformationName}
            onChange={handleChange}
          />
        </div>
        <div class="form-group row">
          <label class="ContentTitle col-sm-2">Resource File Path</label>
          <input
            class="form-control input-xlarge col-sm-8"
            id="resourceFile"
            value={state.resourceFile}
          />
        </div>
        <div class="form-group row">
          <label class="ContentTitle col-sm-2">Class Name</label>
          <input
            class="form-control input-xlarge col-sm-8"
            id="className"
            value={state.className}
          />
        </div>
        <div class="form-group row">
          <label class="ContentTitle col-sm-2">Description</label>
          <input
            class="form-control input-xlarge col-sm-8"
            id="description"
            value={state.description}
          />
        </div>
        <div class="form-group row">
          <label class="ContentTitle col-sm-2">Version</label>
          <input
            class="form-control input-xlarge col-sm-8"
            id="transformationVersion"
            value={state.transformationVersion}
          />
        </div>
        <div class="form-group row">
          <label class="ContentTitle col-sm-2">Status</label>
          {/* <SelectListComponent IdName="statusCode" onChange={this.handleChange} entityName ={"system-code-values"}
					valueFilterColumnName = {"systemCodeId"} valueFilter = {"95"} displayColumnName ={"systemCodeValueValue"}
					keyColumnName ={"systemCodeValueId"}/> */}

          <select
            partialSubmit="true"
            class="form-control input-xlarge col-sm-8"
            id="indexCode"
            value={state.statusCode}
          >
            {/* <option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('TRANSFORMATION_STATUS')}" /> */}
            {status.map(
                  ({
                    systemCodeId,
                    systemCodeValueValue,
                    systemCodeValueId,
                  }) => (
                    <option key={systemCodeValueId} value={systemCodeId}>
                      {systemCodeValueValue}{" "}
                    </option>
                  )
                )}

          </select>
        </div>
        <div class="form-group row">
          <label class="ContentTitle col-sm-2">Parameters</label>
          <textArea
            class="form-control input-xlarge col-sm-8"
            id="parameters"
            value={state.parameters}
          />
        </div>

        <div class="form-group row">
          <label class="ContentTitle col-sm-2">Upload Transformer</label>
          <input type="file" id="fileUpload" />
          <button
            type="submit"
            value="Upload"
            class="btn btn-info"
            id="uploadActor"
          >
            Upload
          </button>
        </div>

        <hr />
        <center>
          <div class="form-group row">
            <button class="btn btn-success" value="Save" onClick={handleSave}>
              Save
            </button>
            &nbsp;
            <button
              class="btn btn-primary"
              value="Clear"
              onClick={handleClear}
              immediate="true"
            >
              Clear
            </button>
            &nbsp;
            <button
              class="btn btn-danger"
              value="Delete"
              onClick={handleDelete}
            >
              Delete
            </button>
            &nbsp;
          </div>
        </center>
      </div>
    </form>
    </div>
  );
};

export default ManageTransformer;
