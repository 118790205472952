import React, { createContext, useEffect, useState, useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Loading } from '../Loading'
import { API_PATHS, instance, setToken } from '../../services/index'
import axios from 'axios'
import { globalConfig } from '../../config'

export const AuthContext = createContext({ userMetadata: null })

export const Auth = props => {

//	console.log('inside auth');
	
//	console.log('apiMainBaseUrl - ' + globalConfig.apiMainBaseURL);
//	console.log('************ BAD CODE IN AUTH WITH HARDCODED USERMETADATA MUST BE REMOVED ************')
// 	const userMetadata = useContext(AuthContext)
  	//const [userMetadata, setUserMetaData] = useState({"customerId":1,"membershipType":"3002","userId":520,"apiKey":"linkedin|rz1MycKLaZ","stripe":{"id":"cus_MvcUINmEfdcIFK","object":"customer","subscriptions":{"object":"list","data":[],"has_more":false,"total_count":0,"url":"/v1/customers/cus_MvcUINmEfdcIFK/subscriptions"}}})
  	const { error, isAuthenticated, isLoading, user, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0()
  	//const [isLoading, setIsLoading] = useState(false)
   	const [userMetadata, setUserMetaData] = useState(null)
 	const [hasActiveSubscription, setHasActiveSubscription] = useState(false)

// 	userMetadata = {"customerId":1,"membershipType":"3002","userId":520,"apiKey":"linkedin|rz1MycKLaZ","stripe":{"id":"cus_MvcUINmEfdcIFK","object":"customer","subscriptions":{"object":"list","data":[],"has_more":false,"total_count":0,"url":"/v1/customers/cus_MvcUINmEfdcIFK/subscriptions"}}}
//	setUserMetaData(getUserMetadata());

// 	console.log('useAuth0.user - ' + JSON.stringify(user));
// 	console.log('useAuth0.error - ' + JSON.stringify(error));
// 	console.log('useAuth0.isAuthenticated - ' + isAuthenticated);
// 	console.log('useAuth0.isLoading - ' + isLoading);
	if (!isAuthenticated && !isLoading)
	{
// 		console.log('user is not authenticated.');
		loginWithRedirect();
 		//logout();
	}


  	const getUserMetadata = async () => {
	    try 
	    {
//     		console.log('getting usermetadata');
      		//setIsLoading(true)
      		const baseURL = `${globalConfig.auth0.audience}`;
      		//'https://origotelehealth.auth0.com/api/v2/'
// 			console.log('1 ' + baseURL);
      		const userDetailsByIdUrl = `${baseURL}users/${user.sub}`
// 			console.log('2 ' + userDetailsByIdUrl);
		
// 			console.log('userdetails - ' + userDetailsByIdUrl);
		
		    const accessToken = await getAccessTokenSilently({
		    	authorizationParams: {
	        		audience: baseURL,
    	    		scope: 'read:current_user',
		    	},
      		});
// 			console.log('token - ' + accessToken);

		    const metadataResponse = await fetch(userDetailsByIdUrl, {
        		headers: {
          			Authorization: `Bearer ${accessToken}`,
        		},
      		});

//	      	console.log("user metadata -> " + JSON.stringify(metadataResponse));
      		const { user_metadata: userMeta } = await metadataResponse.json()
//       		console.log('meta->' +JSON.stringify(userMeta))

      
      		if (userMeta) {
        		setToken(`${userMeta.apiKey}:${userMeta.userId}`);
        		setUserMetaData(userMeta);
        		setHasActiveSubscription(true);        	
     	 	}
	
      //setIsLoading(true)
      
      //console.log('props - ' + JSON.stringify(props.children));
      
    	} 
    	catch (error) 
    	{
//     		console.log(error);
			logout();
    	}
    	finally
    	{
//     		console.log('done handling errors')
    	}
    	//console.log('props - ' + JSON.stringify(props.children));
//    console.log('Auth.usermeta - ' + JSON.stringify(userMetadata));

	

  	}

  useEffect(() => {
    getUserMetadata()
  }, [user])


  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {
      (hasActiveSubscription) ? (
        <AuthContext.Provider value={userMetadata}>
          {props.children}
        </AuthContext.Provider>
      )  : (<AuthContext.Provider value={userMetadata}>
      		 {props.children}
      		</AuthContext.Provider>
      		)
       }
    </>
  )
}
