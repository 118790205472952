import React, {Component} from 'react';
import {  Link } from 'react-router-dom';
import axios from 'axios';
import { globalConfig } from '../config'
const querystring = require('querystring');

export default class Transformers extends Component {

	constructor(props)
	{
		console.log(props);
    super(props);
  }

  state = {
    transformations: []
  };

	componentDidMount() 
	{
		this.handleOnLoad();
	}

	handleOnLoad = () => {
      axios.get(`${globalConfig.apiDatahubUrl}/transformations/`, globalConfig.hardcoded_header).then(res => {
      const transformations = res.data;
      console.log(transformations);
      this.setState({ transformations });
      console.log(res.data[0]);
		});
		
	}
	
	render(){
    return (
		<form>
		<div class="container-fluid">

	<div className="d-flex flex-row justify-content-between mb-3 mt-3">
			  <h4>Transformations</h4>
            <Link to="/create-transformer" className="btn-success btn">
              Create Transformer
            </Link>
      </div>


      <div className="form-group row">
      	<table class="table table-bordered table-striped">
      	<thead>
      	<tr>
      	<th scope="col">#</th>
      	<th scope="col">Name</th>
     	<th scope="col">Description</th>
     	<th scope="col">Action</th>
    	</tr>
  		</thead>
  		<tbody>

          {this.state.transformations.map( ( {transformationName, transformationTypeCode, transformationId} ) => {
  					  return <tr>
  					  	
  					  	<td class="ContentTitle">
                          <Link
                            to={{
                              pathname: `/manage-transformer/${transformationId}`,
                            }}
                          >
                            {transformationId}{" "}
                          </Link>
                        </td>
                        <td class="ContentTitle">
                          <Link
                            to={{
                              pathname: `/manage-transformer/${transformationId}`,
                            }}
                          >
                            {transformationName}{" "}
                          </Link>
                        </td>
                        <td class="ContentTitle">
                          <Link
                            to={{
                              pathname: `/manage-transformer/${transformationId}`,
                            }}
                          >
                            {transformationTypeCode}{" "}
                          </Link>
                        </td>

  					  	
  					  	<td><button class="btn btn-danger">Delete</button></td>
  					  	</tr>
					}) 
				}
        </tbody>
        </table>
      </div>
      </div>
		</form>		
	  );
	}
}