import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { globalConfig } from "../config";
import Pagination from "../utils/Paginate";
const querystring = require("querystring");

const Services = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [state, setState] = useState({ services: [], filteredServices: [] });

  // effect for loading services
  useEffect(() => {
    handleOnLoad();
  }, []);

  useEffect(() => {
    handleSearch(search);
  }, [search]);

  //Loading all services
  const handleOnLoad = () => {
  	try
  	{
  		console.log('getting services');
    axios
      .get(
        `${globalConfig.apiDatahubUrl}/scan-jobs`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const services = res.data;
        console.log(services);
        setState({ ...state, services: services, filteredServices: services });
        console.log(res.data[0]);
      });
      }
      catch(e)
      {
      	console.log(e);
      }
  };

  // handling delete
  const handleDelete = (id, event) => {
    event.preventDefault();
    //alert('deleting service with id = ' + id);
    axios
      .delete(`${globalConfig.apiDatahubUrl}/scan-jobs/${id}`, {
        headers: {
          Authorization: `Bearer 9a0641b5-7c8b-4f30-bf24-69248863a6e2:1279`,
        },
      })
      .then((res) => {
        const deleteResponse = res.data;
        console.log(deleteResponse);
      });
    setState({ ...state, services: [], filteredServices: [] });
    handleOnLoad();
  };

  const indexOfLastData = currentPage * globalConfig.dataPerPage;
  const indexOfFirstData = indexOfLastData - globalConfig.dataPerPage;
  const currentData = state.filteredServices.slice(
    indexOfFirstData,
    indexOfLastData
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //handler for page
  const handlePage = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (search) => {
    let URL = `${globalConfig.apiDatahubUrl}/scan-jobs?`;
    URL += `&filter[where][or][0][scanJobName][regexp]=/(${search})/i`;
    axios.get(URL, globalConfig.hardcoded_header).then((res) => {
      const services = res.data;
      console.log(services);
      setState({ ...state, filteredServices: services });
      console.log(res.data[0]);
    });
  };

  return (
    <div class="container-fluid">

    <form>
      <div class="container-fluid">
        <div className="d-flex flex-row justify-content-between mb-3 mt-3">
          <h4>Data Services</h4>
          <Link to="/create-service" className="btn-success btn">
            Create Service
          </Link>
        </div>
        <div class="form-group">
          <input
            className="form-control"
            type="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            id="exampleFormControlInput1"
            placeholder="Search"
          />
        </div>
        <div className="form-group row">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map(({ scanJobName, scanJobId }) => {
                return (
                  <tr key={scanJobId}>
                    <td>
                      <Link
                        to={{
                          pathname: "/manage-service",
                          search: `?id=${scanJobId}`,
                        }}
                      >
                        {scanJobId}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={{
                          pathname: "/manage-service",
                          search: `?id=${scanJobId}`,
                        }}
                      >
                        {scanJobName}
                      </Link>
                    </td>
                    <td>
                      <button class="btn btn-success">Start</button>&nbsp;
                      <button class="btn btn-warning">Stop</button>&nbsp;
                      <button class="btn btn-primary">Transform</button>&nbsp;
                      <button
                        class="btn btn-danger"
                        onClick={(e) => handleDelete(scanJobId, e)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        dataPerPage={globalConfig.dataPerPage}
        totaldata={state.filteredServices.length}
        paginate={paginate}
        currentPage={currentPage}
        onChange={handlePage}
      />
    </form>
    </div>
  );
};

export default Services;
