import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { globalConfig } from "../config";

import axios from "axios";
import { SelectListComponent } from "shared-components";
const querystring = require("querystring");

const ManageConnector = ({ location }) => {
  //let parms = JSON.stringify(querystring.parse(location.search));
  const {id } = useParams(); 
  //parms = parms.replace("?", "");
  console.log("parms id => " + id);
  const [message, setMessage] = useState("");
  const [contentInputTypes, setContentInputTypes] = useState([]);
  const [state, setState] = useState({
    contentInputSourceId: id,
    contentInputSourceName: "",
    contentSourceDescr: "",
    contentSourceUrl: "",
    contentSourceType: 0,
    sourceIconType: 0,
    transformerFlag: 0,
    transformerId: 0,
    contentTargetUrl: "",
    contentTargetType: 0,
    targetIconType: 0,
    timeoutPeriod: 0,
    securityContextId: 0,
    lastUpdateUser: 0,
    lastUpdateDate: new Date(),
    sourceUsername: "",
    sourcePassword: "",
    targetUsername: "",
    targetPassword: "",
  });
  const [transformers, setTransformers] = useState([]);

  useEffect(() => {
    handleOnLoad();
  }, []);
  //handle changes to fields
  function handleChange(event) {
    console.log(event);
    var target = event.target;
    console.log(target.id + ":" + target.value);
    setState({ ...state, [target.id]: target.value });

    console.log(state);
  }

  //save edited connector
  function handleSave(event) {
    console.log("handling save 1");

    event.preventDefault();
    console.log("handling save");

    var url = `${globalConfig.apiDatahubUrl}/content-input-sources/${state.contentInputSourceId}`;

    axios
      .patch(url, state, globalConfig.hardcoded_header)
      .then((response) => {
        console.log("response.data");
        console.log(response.status + ":" + response.data.contentInputSourceId);

        if (response.status === 200) {
          setMessage("Success");
        } else {
          setMessage("Failed");
        }
        console.log(message);
        event.preventDefault();
      })
      .catch((err) => {
        console.log("error - " + err);
      });
    console.log("message info - " + message);

    handleClear();
    setInterval(() => {
      window.location.reload();
    }, 1000);
  }

  // clear fields
  function handleClear(event) {
    setState({
      contentInputSourceId: 0,
      contentInputSourceName: "",
      contentSourceDescr: "",
      contentSourceUrl: "",
      contentSourceType: 0,
      sourceIconType: 0,
      transformerFlag: 0,
      transformerId: 0,
      contentTargetUrl: "",
      contentTargetType: 0,
      targetIconType: 0,
      timeoutPeriod: 0,
      securityContextId: 0,
      lastUpdateUser: 0,
      lastUpdateDate: new Date(),
      sourceUsername: "",
      sourcePassword: "",
      targetUsername: "",
      targetPassword: "",
    });
  }
  //handle deleting the connector
  const handleDelete = (event) => {
    event.preventDefault();
    console.log("deleting connector with id = " + state.contentInputSourceId);
    axios
      .delete(
        `${globalConfig.apiDatahubUrl}/content-input-sources/${state.contentInputSourceId}`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const results = res.data;
        console.log(results);
        console.log(res.data[0]);
        window.location.replace("/connectors");
      });
  };
  //handle loading the connector
  const handleOnLoad = () => {
    console.log("handleOnLoad() - loading id = " + state.contentInputSourceId);
    axios
      .get(
        `${globalConfig.apiDatahubUrl}/content-input-sources/${state.contentInputSourceId}`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const connector = res.data;
        console.log("connector");
        console.log(connector);
        setState(connector);
        console.log("state");
        console.log(state);
      });
    axios
      .get(
        `${globalConfig.apiDatahubUrl}/transformations/`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const transformations = res.data;
        console.log(transformations);
        setTransformers(transformations);
      });

    axios
      .get(
        `${globalConfig.systemCodesUrl}/CONTENT_INPUT_SOURCE_TYPE`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const sourceTypes = res.data;
        console.log(sourceTypes);
        setContentInputTypes(sourceTypes);
      });
  };

  function handleChangeConnector(event) {
    console.log("handling a change in the current connector");

    alert("changing the current connector");

    console.log(event);
    var target = event.target;
    console.log(target.id + ":" + target.value);
    setState({
      [target.id]: target.value,
    });

    axios
      .get(
        `${globalConfig.apiDatahubUrl}/content-input-sources/${state.newContentInputSourceId}`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const connector = res.data;
        console.log("connector");
        console.log(connector);
        this.setState(connector);
        console.log("state");
        console.log(state);
      });
    console.log(state);
  }

  return (
    <div class="container-fluid">
      <form>
        <div class="container-fluid">
          <Link
            to="/connectors"
            className="btn btn-outline-secondary mt-2 mb-3 "
          >
            Back
          </Link>
          <h4>Manage Connector</h4>
          <hr />
          <h4>Select Connector</h4>
          <div class="form-group row">
            <label class="ContentTitle col-sm-2" value="Name: ">
              Connector
            </label>
            {/* <SelectListComponent IdName="newContentInputSourceId" onChange={this.handleChangeConnector} entityName ={"content-input-sources"}
						valueFilterColumnName = {"contentInputSourceId"}  displayColumnName ={"contentInputSourceName"}
						keyColumnName ={"contentInputSourceId"} valueId={this.state.contentSourceType} /> */}

            <select
              class="form-control input-xlarge col-sm-8"
              id="contentInputSourceId"
              value={state.contentInputSourceId}
              onChange={handleChange}
            ></select>
          </div>
          <hr />
          <h4>Source</h4>
          <div columns="2">
            <div class="form-group row">
              <label class="ContentTitle col-sm-2" value="Name: ">
                Name
              </label>
              <input
                class="form-control input-xlarge col-sm-8"
                id="contentInputSourceName"
                value={state.contentInputSourceName}
                onChange={handleChange}
              />
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Description</label>
              <input
                class="form-control input-xlarge col-sm-8"
                id="contentSourceDescr"
                value={state.contentSourceDescr}
                onChange={handleChange}
              />
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Source URL</label>
              <input
                class="form-control input-xlarge col-sm-8"
                id="contentSourceUrl"
                value={state.contentSourceUrl}
                onChange={handleChange}
              />
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Source Type</label>
              {/* <SelectListComponent IdName="contentTargetType" onChange={this.handleChange} entityName ={"system-code-values"}
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"33"} displayColumnName ={"systemCodeValueValue"}
				keyColumnName ={"systemCodeValueId"} valueId={this.state.contentSourceType} /> */}

              <select
                class="form-control input-xlarge col-sm-8"
                id="contentSourceType"
                value={state.contentSourceType}
                onChange={handleChange}
              >
                {contentInputTypes.map(
                  ({
                    systemCodeId,
                    systemCodeValueValue,
                    systemCodeValueId,
                  }) => (
                    <option key={systemCodeValueId} value={systemCodeId}>
                      {systemCodeValueValue}{" "}
                    </option>
                  )
                )}
                {/* <option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('CONTENT_INPUT_SOURCE_TYPE')}" />
                <message for="srcType" /> */}
              </select>
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Source User Name</label>
              <input
                class="form-control input-medium col-sm-8"
                id="sourceUsername"
                value={state.sourceUsername}
                onChange={handleChange}
              />
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Source Password</label>
              <input
                type="password"
                class="form-control input-medium col-sm-8"
                id="sourcePassword"
                value={state.sourcePassword}
                onChange={handleChange}
              />
            </div>
          </div>

          <hr />
          <h4>Target</h4>

          <div columns="2">
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Target URL</label>
              <input
                class="form-control input-xlarge col-sm-8"
                id="contentTargetUrl"
                value={state.contentTargetUrl}
                onChange={handleChange}
              />
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Target User Name</label>
              <input
                class="form-control input-xlarge col-sm-8"
                id="targetUsername"
                value={state.targetUsername}
                onChange={handleChange}
              />
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Target Password</label>
              <input
                type="password"
                class="form-control input-xlarge col-sm-8"
                id="targetPassword"
                value={state.targetPassword}
                onChange={handleChange}
              />
            </div>

            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Target Type</label>
              {/* <SelectListComponent IdName="contentTargetType" onChange={this.handleChange} entityName ={"system-code-values"}
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"33"} displayColumnName ={"systemCodeValueValue"}
				keyColumnName ={"systemCodeValueId"}/> */}

              <select
                class="form-control input-xlarge col-sm-8"
                id="contentTargetType"
                value={state.contentTargetType}
                onChange={handleChange}
              >
                {/* <option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('CONTENT_INPUT_SOURCE_TYPE')}" />
                <message for="targetType" /> */}
                {contentInputTypes.map(
                  ({
                    systemCodeId,
                    systemCodeValueValue,
                    systemCodeValueId,
                  }) => (
                    <option key={systemCodeValueId} value={systemCodeId}>
                      {systemCodeValueValue}{" "}
                    </option>
                  )
                )}
              </select>
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Core Transformation</label>
              {/* <SelectListComponent IdName="transformerId" onChange={this.handleChange} entityName ={"transformations"}
					keyColumnName={"transformationId"} displayColumnName ={"transformationName"}
					valueFilterColumnName={"transformationId"} valueId={this.state.transformerId} /> */}

              <select
                class="form-control input-xlarge col-sm-8"
                id="transformerId"
                value={state.transformerId}
                onChange={handleChange}
              >
                {/* <option value="#{ombTransformationForm.transformations}" />
              <message for="transformerId" /> */}
                <option>Select A Transformer</option>
                {transformers.map(
                  ({ transformationName, transformationId }, index) => (
                    <option key={index} value={transformationId}>
                      {transformationName}
                    </option>
                  )
                )}
              </select>
            </div>
            <div class="form-group row">
              <label class="ContentTitle col-sm-2">Transformer On</label>
              <input
                type="checkbox"
                id="transformerFlag"
                value={state.transformerFlag}
                onChange={handleChange}
              />
            </div>
          </div>

          <hr />
          <div className="container-fluid">
            <div class="form-group row">
              &nbsp;&nbsp;
              <button value="Save" class="btn btn-success" onClick={handleSave}>
                Save
              </button>
              &nbsp;
              <button
                value="Delete"
                class="btn btn-danger"
                onClick={handleDelete}
              >
                Delete
              </button>
              &nbsp;
              <button
                value="Clear"
                class="btn btn-primary"
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
            <br />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ManageConnector;
